import { gql } from "@urql/core";

export const metafields = gql`
  fragment metafields on Metafield {
    value
    type
    id
    key
    namespace
  }
`;
