import { gql } from "@urql/core";
import { mailingAddress } from "./address";
import { metafields } from "./metafields";

export const customer = gql`
  ${mailingAddress}
  ${metafields}
  fragment customer on Customer {
    acceptsMarketing
    createdAt
    addresses(first: 100) {
      nodes {
        ...mailingAddress
      }
    }
    defaultAddress {
      ...mailingAddress
    }
    metafields(identifiers: { key: "invited_emails", namespace: "referrals" }) {
      ...metafields
    }
    displayName
    email
    firstName
    id
    lastName
    phone
    tags
    updatedAt
  }
`;
